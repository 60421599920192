import * as React from "react";
import Page from "../containers/Page";
import Hero from "../components/Hero";
import Stripes from "../images/stripes.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import "./../style/custom-style.css";
import Card from "../components/Card";

import DoBest from "../images/do-best.svg";
import Experience from "../images/ervaring.svg";
import Personel from "../images/geen-personeel.svg";
import LessCosts from "../images/minder-kosten.svg";
import Independant from "../images/onafhankelijk.svg";
import CustomSize from "../images/op-maat.svg";

// markup
const IndexPage = () => {
  return (
    <Page>
      <Hero>
        <h1>
          Administratieve
          <br />
          ondersteuning van <em className="blue">A</em>{" "}
          <em className="purple">tot</em> <em className="yellow">Z</em>
        </h1>
      </Hero>
      <div className="container-fluid">
        <div className="container">
          <section className="mt-5">
            <div className="stripes">
              <img src={Stripes} alt="" />
            </div>

            <div className="intro">
              <p>
                FlexOffice helpt graag alle ondernemers en sterk groeiende KMO's
                met hun administratie.{" "}
              </p>

              <p>
                Is uw administratie zo hard gegroeid of aan het groeien dat dit
                te veel wordt? Doe dan zeker beroep op mij! Samen bekijken we
                alles, zet ik het allemaal terug op orde en kan u terug verder
                met uw passie.
              </p>
              <p>
                Afgestudeerd als management assistent en met meerdere jaren
                ervaring in aankoop, backoffice en boekhoudassistentie heb ik al
                heel wat kennis kunnen opbouwen. Hierdoor kan ik vast en zeker
                ook iets voor u betekenen.
              </p>
            </div>
          </section>
        </div>
        <section className="mt-5 line-bg">
          <div className="container">
            <h2>Services</h2>
            <small>Inzetbaar bij elke administratieve taak</small>
            <Card
              readMore="/services"
              anchor="algemene-administratie"
              img="/images/algemene-administratie.png"
              title="Algemene administratie"
              description="In deze digitale wereld is het belangrijk om ‘mee’ te zijn. Weet
              je geen beginnen aan de verwerking van alle gegevens of vind je
              dit maar ‘saai en tijdrovend’? Ben je het overzicht, in het kluwen
              van vergaderingen en afspraken, kwijt? Klik dan hier om erachter
              te komen hoe ik jou kan helpen."
            />
            <Card
              readMore="/services"
              anchor="financiele-administratie"
              reverse={true}
              img="/images/financiele-administratie.png"
              title="Financiële administratie"
              description="Binnenkomende en buitengaande facturen moeten aan heel wat eisen voldoen. Wil je graag nieuwe leverancierscontracten onderhandelen wat een kostenbesparing kan opleveren? En niet alle klanten betalen zo stipt als u zou willen! Wenst u hierin ondersteuning of begeleiding? Klik hier om te kijken wat ik voor u kan betekenen."
            />
            <Card
              readMore="/services"
              anchor="commerciele-administratie"
              img="/images/commerciele-administratie.png"
              title="Commerciële administratie"
              description="Een belangrijke presentatie opmaken, binnengekomen leads opvolgen, teksten vertalen voor een potentieel grote klant of leverancier, gepersonaliseerd drukwerk laten maken… . Allemaal zaken die je vroeg of laat wel eens tegenkomt. Daar kan ik u bij helpen. Klik hier en ontdek hoe."
            />
          </div>
        </section>
        <section className="mt-5 p-5 bg-light-blue">
          <div className="container">
            <h2>Voordelen</h2>
            <small>Full-focus op uw kernactivieit</small>

            <div className="row row-cols-1 row-cols-md-3 g-4">
              <div className="col">
                <div className="card nopointer border-0 bg-transparent">
                  <img
                    src={CustomSize}
                    className="card-img-top advantage-icon"
                    alt="Icoon stopwatch: wij zijn er wanneer het jou uitkomt"
                  />
                  <div className="card-body">
                    <h5 className="card-title">
                      Wij zijn er wanneer u ons nodig heeft
                    </h5>
                    <p className="card-text">
                      Je schakelt FlexOffice in wanneer het jou uitkomt.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card nopointer border-0 bg-transparent">
                  <img
                    src={LessCosts}
                    className="card-img-top advantage-icon"
                    alt="Icoon Minder kosten"
                  />
                  <div className="card-body">
                    <h5 className="card-title">Minder kosten</h5>
                    <p className="card-text">
                      Je hebt geen kosten bij ziekte en betaalt geen
                      vakantiegeld of 13de maand
                    </p>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card nopointer border-0 bg-transparent">
                  <img
                    src={Independant}
                    className="card-img-top advantage-icon"
                    alt="Icoon onafhankelijkheid"
                  />
                  <div className="card-body">
                    <h5 className="card-title">Onafhankelijk</h5>
                    <p className="card-text">
                      Je beschikt over een onafhankelijk klankbord binnen jouw
                      organisatie.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card nopointer border-0 bg-transparent">
                  <img
                    src={DoBest}
                    className="card-img-top advantage-icon"
                    alt="Icoon: doe wat je het beste doet."
                  />
                  <div className="card-body">
                    <h5 className="card-title">Do what you do best!</h5>
                    <p className="card-text">
                      Je hebt meer tijd om je te focussen op je
                      kernactiviteiten.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card  nopointer border-0 bg-transparent">
                  <img
                    src={Experience}
                    className="card-img-top advantage-icon"
                    alt="Icoon ervaring en dienstverlening"
                  />
                  <div className="card-body">
                    <h5 className="card-title">
                      Administratie zoals het hoort
                    </h5>
                    <p className="card-text">
                      Snelle, stipte en professionele dienstverlening, gebaseerd
                      op jarenlange ervaring.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card  nopointer border-0 bg-transparent">
                  <img
                    src={Personel}
                    className="card-img-top advantage-icon"
                    alt="Icoon geen extra personeel"
                  />
                  <div className="card-body">
                    <h5 className="card-title">Geen extra personeel nodig</h5>
                    <p className="card-text">
                      Geen administratieve rompslomp bij aanwerving.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Page>
  );
};

export default IndexPage;
