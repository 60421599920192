import React from "react";
import { Link } from "gatsby";

const Img = (props) => {
  return (
    <div className="col-md-4">
      <img
        src={props.img}
        className={`img-fluid ${props.reverse ? "reverse" : ""}`}
        alt={props.title}
      />
    </div>
  );
};
const Card = (props) => {
  return (
    <Link
      to={props.readMore}
      title={props.title}
      state={{
        anchor: props.anchor,
      }}
    >
      <div className={`card mb-3 ${props.nopointer ? "nopointer" : ""}`}>
        <div className="row g-0">
          {!props.reverse && <Img img={props.img} reverse={props.reverse} />}
          <div
            className={props.reverse ? "card-spacer reverse" : "card-spacer"}
          />
          <div className="col-md-8">
            <div className="card-body p-5">
              <h5 className="card-title">{props.title}</h5>
              <p className="card-text">{props.description}</p>
            </div>
            <div
              className={`card-footer bg-transparent border-white ${
                props.reverse ? "text-start ps-5" : "text-end pe-5"
              }`}
            >
              {props.readMore && <a href={props.readMore}>Lees meer ›</a>}
            </div>
          </div>
          {props.reverse && <Img img={props.img} reverse={props.reverse} />}
        </div>
      </div>
    </Link>
  );
};

export default Card;
